import React from 'react'
import './footer.css'
import { Link } from 'react-router-dom'

class Footer extends React.Component {

  render() {
    return (
      <footer>
          <div>
            <Link to='/' className='footerLogo'><img alt='Logo Koh Gaming' src='/svg/logo.svg' width='120' /></Link>
            <div className='footerSocial'>
              <a
                title='Facebook de KoH Gaming'
                rel='noopener noreferrer'
                href='https://fb.me/koh.gaming.es'
                target='_blank'
              >
                <img alt='Logo Facebook' src='/svg/facebook_white.svg' height='18' />
              </a>
              <a
                title='Instagram de KoH Gaming'
                rel='noopener noreferrer'
                href='https://instagram.com/_kohgaming'
                target='_blank'
              >
                <img alt='Logo Instagram' src='/svg/instagram_white.svg' height='18' />
              </a>
              <a
                title='Twitter de KoH Gaming'
                rel='noopener noreferrer'
                href='https://twitter.com/_KoHGaming'
                target='_blank'
              >
                <img alt='Logo Twitter' src='/svg/twitter_white.svg' height='18' />
              </a>
              <a
                title='Youtube de KoH Gaming'
                rel='noopener noreferrer'
                href='https://www.youtube.com/channel/UCDhS_joPkKwFHmkqvHgh1hw'
                target='_blank'
              >
                <img alt='Logo Youtube' src='/svg/youtube_white.svg' height='18' />
              </a>
              <a
                title='Twitch de KoH Gaming'
                rel='noopener noreferrer'
                href='https://twitch.tv/kohgaminges'
                target='_blank'
              >
                <img alt='Logo Twitch' src='/svg/twitch_white.svg' height='18' /></
              a>
            </div>
          </div>
          <div className='footerLegal'>
            <p>© Copyright 2019. All Rights Reserved.</p>
            <div>
              <Link to='/privacy-policy'>Privacy Policy</Link>
              <Link to='/terms-of-service'>Terms of Service</Link>
            </div>
          </div>
      </footer>
    )
  }
}

export default Footer