import React from 'react'
import './OneGame.css'
import OneTeam from '../OneTeam/OneTeam'
import SliderTeams from '../SliderTeams/SliderTeams'

class OneGame extends React.Component {
  constructor () {
    super()
    
    this.state = {
      selectedTeam: ''
    }
    this.handleClick = this.handleClick.bind(this)
    this.close = this.close.bind(this)
  }
  close () {
    this.setState({selectedTeam: ''})
  }
  handleClick (info) {
    return i => {
      const selectedTeam = this.state.selectedTeam && this.state.selectedTeam._id === info._id ? '' : info
      this.setState({ selectedTeam })
      if (selectedTeam) {
        setTimeout(i => {
          let offset = document.getElementById(`team-${info.name}`).offsetTop-100
          window.scroll({
            top: offset, 
            left: 0, 
            behavior: 'smooth'
          })
        }, 100)
      }
    }
  }
  render() {
    return (
      <div style={{backgroundColor: '#00000005'}}>
        <div className='OneGame' style={{backgroundImage: `url(/image/${this.props.info.tag}.jpg)`}}>
          <div>
            <div>
              <h2>{this.props.info.name}</h2>
            </div>
            <SliderTeams
              info={this.props.info.teams}
              selectedTeam={this.state.selectedTeam}
              handleClick={this.handleClick}
              device={this.props.device}
              width={this.props.width}
            />
          </div>
        </div>
        <OneTeam
          close={this.close}
          info={this.state.selectedTeam}
        />
      </div>
    )
  }
}

export default OneGame