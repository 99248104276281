import React from 'react'
import {Link} from 'react-router-dom'
import './header.css'
class Header extends React.Component {
  constructor() {
    super()
    this.state = {width: 1200, open: false, scroll: false}
    
    this.handleResize = this.handleResize.bind(this)
    this.handleScroll = this.handleScroll.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.close = this.close.bind(this)
  }
  componentDidMount () {
    this.handleResize()
    window.addEventListener('resize', this.handleResize)
    window.addEventListener('scroll', this.handleScroll)
    let bg = document.getElementById('background')
    bg.onclick = this.close
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
    window.removeEventListener('scroll', this.handleScroll)
  }
  handleResize () {
    this.setState({width: window.innerWidth})
  }
  handleScroll () {
    const scroll = window.scrollY >= 30
    if (scroll !== this.state.scroll) this.setState({ scroll })
  }
  handleClick () {
    let bg = document.getElementById('background')
    bg.className = this.state.open ? 'backgroundClosed' : 'backgroundOpen'
    
    this.setState({open: !this.state.open})
  }
  close () {
    let bg = document.getElementById('background')
    bg.className = 'backgroundClosed'
    this.setState({open: false})
  }
  render() {
    return (this.state.width <= 600)
    ? <header className={this.state.scroll ? 'headerScroll' : ''}>
        <div>
          <div onClick={this.close}><Link to='/'><img alt='Logo Koh Gaming' src='/svg/logo.svg' /></Link></div>
          <button onClick={this.handleClick}><img alt='Menu Icon' src='/svg/menu.svg' /></button>
        </div>
        <div onClick={this.close} className='headerMobileMenu' style={{display: this.state.open ? 'flex' : 'none'}}>
          <Link to='/'>Home</Link>
          <Link to='/teams'>Equipos</Link>
          <Link to='/contact'>Contacto</Link>
          {/* <Link to='/about'>About</Link> */}
        </div>
    </header>
    : <header>
        {/* <Link to='/'>Home</Link> */}
        <Link to='/teams'>Equipos</Link>
        <Link to='/'><img alt='Logo Koh Gaming' src='/svg/logo.svg' /></Link>
        <Link to='/contact'>Contacto</Link>
        {/* <Link to='/about'>About</Link> */}
      </header>
  }
}

export default Header