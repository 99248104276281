import React from 'react'
import './team.css'
import teams from '../../data/teams.json'
import NotFound from '../NotFound/NotFound'
import OnePlayer from '../../components/OnePlayer/OnePlayer'
class Team extends React.Component {
  constructor (props) {
    super(props)
    // prepare data
    let team = teams.find(i => `/team${i.url}` === this.props.location.pathname)
    // put it to state
    this.state = {
      ...team
    }
  }
  componentDidMount () {
    window.scrollTo(0, 0)
  }
  render() {
    if (!this.state.name) return <NotFound />
    console.log(this.state);
    
    return (
      <div id='team'>
        <div className='teamHeader' style={{backgroundImage: `url("/image/${this.state.game}.jpg")`}}>
          <div>
            <h1>{this.state.name}</h1>
          </div>
        </div>
        <div className='teamBody'>
          <div className='teamBodyTeam'>
            {
              this.state.players.map((i, c) => <OnePlayer
                key={c}
                info={i}
              />)
            }
          </div>
          {
            this.state.staff.length
            ? <div className='teamBodyStaff'>
              {
                this.state.staff.map((i, c) => <div key={c}>
                  <div style={{backgroundImage:  `url("/image${i.image ? i.image : '/logo_dark.png'}")`}}></div>
                  <div>
                    <h4>{i.name}</h4>
                    <p>{i.position}</p>
                  </div>
                </div>)
              }
            </div>
            : <div />
          }
        </div>
      </div>
    )
  }
}

export default Team