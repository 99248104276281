import React from 'react'
import './terms.css'

class Terms extends React.Component {
  render() {
    return (
      <div className='Terms'>
        TERMS
      </div>
    )
  }
}

export default Terms