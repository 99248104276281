import React from 'react'
import './teams.css'
import games from '../../data/games.json'
import teams from '../../data/teams.json'

import OneGame from '../../components/OneGame/OneGame'

class Teams extends React.Component {
  constructor () {
    super()

    let nPlayers = 0
    if (teams.length > 1) teams.forEach(i => nPlayers += i.players.length)
    else nPlayers = teams[0].players.length
    
    this.state = {
      games,
      teams,
      nPlayers,
      info: this.modifyData(teams, games),
      device: false,
      width: undefined
    }
    this.handleResize = this.handleResize.bind(this)
  }
  modifyData (teams, games) {
    let info = games.map(g => {
      g.teams = teams.filter(t => t.game === g.tag)

      return g
    })
    return info
  }
  componentDidMount () {
    window.scrollTo(0, 0)

    this.handleResize()

    window.addEventListener('resize', this.handleResize)
    const device = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent))
    this.setState({device})
  }
  handleResize () {
    const width = window.innerWidth
    this.setState({width})
  }

  render() {
    return (
      <div id='teams'>
        <h1>NUESTROS EQUIPOS</h1>
        <div className='subtitle'>
          <h2>{this.state.teams.length} {this.state.teams.length > 1 ? 'EQUIPOS' : 'EQUIPO'}</h2>
          <h2>{this.state.nPlayers} JUGADORES</h2>
        </div>
        {
          this.state.info.map(i => <OneGame
            props={this.props}
            key={i._id}
            info={i}
            device={this.state.device}
            width={this.state.width}
          />)
        }
      </div>
    )
  }
}

export default Teams