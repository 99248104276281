import React from 'react'
import './notFound.css'

class NotFound extends React.Component {
  render() {
    return (
      <div id='notFound'>
        <h1>404: This page was not found</h1>
      </div>
    )
  }
}

export default NotFound