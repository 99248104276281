import React from 'react'
import './OnePlayer.css'

class OnePlayer extends React.Component {
  constructor() {
    super()
    this.state = {
      loaded: false
    }

    this.loaded = this.loaded.bind(this)
  }
  loaded () {
    this.setState({loaded: true})
  }
  getSocialUrl(type, user) {
    let url = ''
    if (type === 'facebook') url = 'https://facebook.com/' + user
    else if (type === 'instagram') url = 'https://instagram.com/' + user
    else if (type === 'twitch') url = 'https://twitch.tv/' + user
    else if (type === 'twitter') url = 'https://twitter.com/' + user
    else if (type === 'op') url = 'https://euw.op.gg/summoner/userName=' + user
    else if (type === 'youtube') url = 'https://youtube.com/' + user

    return url
  }
  render() {
    if (this.props.info === undefined) return <div className='OnePlayer'></div>
    return (
      <div className={this.state.loaded ? 'OnePlayer' : 'OnePlayer OnePlayerLoading'}>
        <div className='OnePlayerInfo'>
          <div>
            <h4>{this.props.info.name}</h4>
            <p>{this.props.info.position}</p>
          </div>
          <div className='OnePlayerSocial'>
            {
              this.props.info.socials.map((i, c) =>
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  href={this.getSocialUrl(i.type, i.user)}
                  key={c}
                >
                  <img alt={`Logo ${i.type}`} src={`/svg/${i.type}.svg`} />
                </a>
              )
            }
          </div>
        </div>
        <img
          src={`/image${this.props.info.image}`}
          alt={`${this.props.info.name}'s profile`}
          onLoad={this.loaded}
          height={this.state.loaded ? 'auto' : 0}
        />
      </div>
    )
  }
}

export default OnePlayer