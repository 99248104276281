import React from 'react'
import './privacy.css'

class Privacy extends React.Component {
  render() {
    return (
      <div className='Privacy'>
        PRIVACY
      </div>
    )
  }
}

export default Privacy