import React from 'react'
import './home.css'
import { TwitterTimelineEmbed } from 'react-twitter-embed'
import TwitchEmbedVideo from 'react-twitch-embed-video'
class Home extends React.Component {
  componentDidMount () {
    window.scrollTo(0, 0)
  }
  render() {
    return (
      <div id='home'>
        <div className='homeTwo'>
          <div className='actualidadText'>
            <div>
              <h2>ACTUALIDAD</h2>
              <p>Siguenos y estate al corriente</p>
            </div>
          </div>
          <div className='homeTwitterWrapper'>
            <TwitterTimelineEmbed
              autoHeight
              noScrollBar
              noHeader={false}
              theme='dark'
              transparent={true}
              sourceType="profile"
              screenName="_KoHGaming"
            />
          </div>
        </div>
        <div className='homeTwo homeTwitch'>
          <div className='homeTwitchWrapper'>
            <TwitchEmbedVideo
              width="100%"
              channel='kohgaminges'
              layout='video'
              theme='dark'
              chat='default'
              autoplay={true}
              mute={true}
            />
          </div>
          <div className='streamText'>
            <div>
              <h2>STREAM</h2>
              <p>Miranos</p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Home