import React from 'react'
import OnePlayer from '../OnePlayer/OnePlayer'
import { Link } from 'react-router-dom'
import './OneTeam.css'

class OneTeam extends React.Component {
  /* 
  
        g.teams = g.teams.map(i => {
        // number to add
        const toAdd = (i.players.length % 5 === 0) ? 5 : i.players.length % 5
        if (toAdd > 0) i.players.push(...new Array(5 - toAdd))
        return i
      })
  */
  render() {
    if (!this.props.info) return '' 
    return (
      <div className='OneTeam' id={`team-${this.props.info.name}`}>
        <div className='OneTeamHeader'>
          <div>
            <h3>{this.props.info.name}</h3>
            <Link className='OneTeamLink' to={`/team${this.props.info.url}`}>Team Page</Link>
          </div>
          <button onClick={this.props.close}><img alt='Close Icon' src='/svg/close.svg' /></button>
        </div>
        <div className='OneTeamBody'>
          {
            this.props.info.players.map((i, c) =>
              <OnePlayer
                key={c}
                info={i}
              />
            )
          }
        </div>
      </div>
    )
  }
}

export default OneTeam