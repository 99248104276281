import React from 'react'
import './SliderTeams.css'

class SliderTeams extends React.Component {
	constructor() {
		super()

		this.state = {
			page: 0,
			items: 5,
			percentage: 20,
			height: 10
		}
		// 24% / 30% / 40% / 60%
		this.nextPage = this.nextPage.bind(this)
		this.prevPage = this.prevPage.bind(this)
	}
	static getDerivedStateFromProps(props, state) {
		if (props.width) {
			let items = 5
			if (props.width < 690) items = 2
			else if (props.width < 870) items = 3
			else if (props.width < 1050) items = 4

			// let height = [0, 0, 60, 40, 30, 24]
			let height = [0, 0, 28, 12, 8, 6]

			let percentage = 100 / items
			if (props.device) percentage = percentage - percentage * 0.07

			return { items, percentage, height: height[items] }
		}

		return null
	}
	nextPage() {
		let page = this.state.page
		if (page + this.state.items < this.props.info.length) page++
		this.setState({ page })
	}
	prevPage() {
		let page = this.state.page
		if (page > 0) page--
		this.setState({ page })
	}

	render() {
		return (
			<div
				className={
					this.props.device ? 'SliderTeams SliderTeamsDevice' : 'SliderTeams'
				}
			>
				<div>
					<div>
						{this.props.info
							? this.props.info.map((i, c) => (
									<div
										className={
											i._id === this.props.selectedTeam._id
												? 'teamSelected oneTeam'
												: 'oneTeam'
										}
										style={{
											width: `${this.state.percentage}%`,
											paddingTop: `${this.state.height}%`
										}}
										onClick={this.props.handleClick(i)}
										key={c}
									>
										<div>
											<p>{i.name}</p>
										</div>
									</div>
							  ))
							: ''}
					</div>
				</div>
			</div>
		)
	}
}

export default SliderTeams
