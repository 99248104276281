import React from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorker'
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import './default.css'
import Home from './pages/Home/Home'
import Teams from './pages/Teams/Teams'
import Team from './pages/Team/Team'
import Header from './components/Header/Header'
import Footer from './components/Footer/Footer'
import Contact from './pages/Contact/Contact'
import About from './pages/About/About'
import Terms from './pages/Terms/Terms'
import Privacy from './pages/Privacy/Privacy'
import NotFound from './pages/NotFound/NotFound'

ReactDOM.render(<Router>
  <Header />
  <Switch>
    <Route exact path='/' component={Home} />
    <Route path='/teams' component={Teams} />
    <Route path='/team/:id' component={Team} />
    <Route path='/contact' component={Contact} />
    <Route path='/about' component={About} />
    <Route path='/privacy-policy' component={Privacy} />
    <Route path='/terms-of-service' component={Terms} />
    <Route component={NotFound} />
  </Switch>
  <Footer />
</Router>, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
