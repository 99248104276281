import React from 'react'
import './contact.css'
import * as emailjs from 'emailjs-com'

class Contact extends React.Component {
  constructor () {
    super()
    this.state = {
      selected: 'player',
      player: {name: '', rank: '', time: '', contact: '', position: '', msg: ''},
      team: {name: '', rank: '', time: '', contact: '', experience: '', msg: ''},
      staff: {contact: '', type: '', msg: ''},
      teamLoading: false,
      teamSuccess: false,
      staffLoading: false,
      staffSuccess: false
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSelected = this.handleSelected.bind(this)
  }
  componentDidMount () {
    window.scrollTo(0, 0)
  }
  selectJob (type, job) {
    return i => this.setState({[type]: job})
  }
  handleSelected (selected) {
    return i => this.setState({selected})
  }
  handleChange (type) {
    return e => {
      const state = this.state[type]
      state[e.target.name] = e.target.value
      this.setState({[type]: state})
    }
  }
  handleSubmit (type) {
    return async i => {
      const pre = type === 'staff' ? 'staff' : 'team'
      try {
        this.setState({[pre + 'Loading']: true})
        i.preventDefault()
        const params = {
          type,
          ...this.state[type],
          subject: pre === 'team'
          ? ` EL ${type.toUpperCase()} ${this.state[type].name} quiere entrar.`
          : `CONTACTO | ${this.state.staff.type.toUpperCase()}`
        }
        const response = await emailjs.send('gmail', pre, params, 'user_loxp5jtip8bDRREbttybj')
        if (response.text === 'OK') {
          this.setState({[pre +'Loading']: false, [pre + 'Success']: true})
        }
        console.log(response.status, response.text)
      } catch (err) {console.log(err);this.setState({[pre +'Loading']: false, [pre + 'Success']: false})}
    }
  }
  render() {
    return (
      <div id='contact'>
        <meta itemprop="embedURL" content="https://kohgaming.com/contact" />
        <h1>CONTACTO</h1>
        <div>
          <div className='contactForm contactTeam'>
            <div>
            </div>
            {
              this.state.teamLoading
              ? <div className='contactSuccess'><img alt='Check Icon' src='/image/spinner.gif' width="60" /><p>Loading</p></div>
              : this.state.teamSuccess
                ? <div className='contactSuccess'><img alt='Check Icon' src='/svg/check.svg' width="60" /><p>Success</p></div>
                : <div>
                <h2>Equipo</h2>
                <div className='contactTwo'>
                  <button onClick={this.handleSelected('player')} className={`contactSelection ${this.state.selected === 'player' ? 'contactSelected':''}`}>Jugador</button>
                  <button onClick={this.handleSelected('team')} className={`contactSelection ${this.state.selected === 'team' ? 'contactSelected':''}`}>Equipo</button>
                </div>
                <form onSubmit={this.handleSubmit(this.state.selected)}>
                  {
                    this.state.selected === 'player'
                    ? <div>
                      <input
                        onChange={this.handleChange('player')}
                        value={this.state.player.name}
                        type='text'
                        name='name'
                        style={{cursor: 'inherit'}}
                        className='contactFormInput'
                        placeholder='Nick en el juego'
                        required
                      />
                      <select className='contactFormInput' required={true} name='position' onChange={this.handleChange('player')} value={this.state.player.position} defaultValue=''>
                        <option disabled value=''>Posición:</option>
                        <option value='superior'>Superior</option>
                        <option value='medio'>Medio</option>
                        <option value='jungla'>Jungla</option>
                        <option value='inferior'>Inferior</option>
                        <option value='apoyo'>Apoyo</option>
                        <option value='ninguna'>Ninguna</option>
                      </select>
                      <div className='contactTwo'>
                        <input
                          onChange={this.handleChange('player')}
                          value={this.state.player.rank}
                          type='text'
                          name='rank'
                          style={{cursor: 'inherit'}}
                          className='contactFormInput'
                          placeholder='Ranking'
                          required
                        />
                        <input
                          onChange={this.handleChange('player')}
                          value={this.state.player.time}
                          type='text'
                          name='time'
                          style={{cursor: 'inherit'}}
                          className='contactFormInput'
                          placeholder='Horario Disponible'
                          required
                        />
                      </div>
                      <input
                        onChange={this.handleChange('player')}
                        value={this.state.player.contact}
                        type='text'
                        name='contact'
                        style={{cursor: 'inherit'}}
                        className='contactFormInput'
                        placeholder='Email o Teléfono'
                        required
                      />
                      <textarea
                        onChange={this.handleChange('player')}
                        value={this.state.player.msg}
                        name='msg'
                        className='contactFormInput'
                        placeholder='Dinos algo sobre tí para conocerte mejor y el juego que juegas'
                        style={{cursor: 'inherit', minHeight: '100px', resize: 'vertical'}}
                      />
                    </div>
                    : <div>
                      <input
                        onChange={this.handleChange('team')}
                        value={this.state.team.name}
                        type='text'
                        name='name'
                        style={{cursor: 'inherit'}}
                        className='contactFormInput'
                        placeholder='Nombre Equipo'
                        required
                      />
                      <input
                        onChange={this.handleChange('team')}
                        value={this.state.team.rank}
                        type='text'
                        name='rank'
                        style={{cursor: 'inherit'}}
                        className='contactFormInput'
                        placeholder='Ranking'
                        required
                      />
                      <input
                        onChange={this.handleChange('team')}
                        value={this.state.team.experience}
                        type='text'
                        name='experience'
                        style={{cursor: 'inherit'}}
                        className='contactFormInput'
                        placeholder='Tiempo cómo equipo'
                        required
                      />
                      <input
                        onChange={this.handleChange('team')}
                        value={this.state.team.contact}
                        type='text'
                        name='contact'
                        style={{cursor: 'inherit'}}
                        className='contactFormInput'
                        placeholder='Email o Teléfono'
                        required
                      />
                      <textarea
                        onChange={this.handleChange('team')}
                        value={this.state.team.msg}
                        name='msg'
                        className='contactFormInput'
                        placeholder='Decidnos algo sobre vosotros para conoceros mejor'
                        style={{cursor: 'inherit', minHeight: '100px', resize: 'vertical'}}
                      />
                    </div>
                  }
                  <input
                    type='submit'
                    className='contactFormInput'
                    value='Enviar'
                  />
                </form>
              </div>
            }
          </div>
          <div className='contactForm contactStaff'>
            {
              this.state.staffLoading
              ? <div className='contactSuccess'><img alt='Spinner Icon' src='/image/spinner.gif' width="60" /><p>Loading</p></div>
              : this.state.staffSuccess
                ? <div className='contactSuccess'><img alt='Check Icon' src='/svg/check.svg' width="60" /><p>Success</p></div>
                : <div>
                <h2>Staff</h2>
                <form onSubmit={this.handleSubmit('staff')}>
                  <div className='contactTwo'>
                    <input
                      onChange={this.handleChange('staff')}
                      value={this.state.staff.contact}
                      type='text'
                      name='contact'
                      style={{cursor: 'inherit'}}
                      className='contactFormInput'
                      placeholder='Email o Teléfono'
                      required
                    />
                    <select className='contactFormInput' required={true} name='type' onChange={this.handleChange('staff')} value={this.state.staff.position} defaultValue=''>
                      <option disabled value=''>Tema:</option>
                      <option value='Coach/Analista'>Coach / Analista</option>
                      <option value='Manager'>Manager</option>
                      <option value='Negocios'>Negocios (Partners / Sponsors)</option>
                      <option value='Prensa'>Prensa / Media</option>
                      <option value='Otro'>Otro</option>
                    </select>
                  </div>
                  <textarea
                    onChange={this.handleChange('staff')}
                    value={this.state.staff.msg}
                    name='msg'
                    className='contactFormInput'
                    placeholder={this.state.staff.type === 'Coach/Analista' ? '¡Explícanos un poco sobre ti! Qué experiencia en equipos has tenido y qué métodos de entrenamiento usas.' : 'Decidnos algo sobre vosotros para conoceros mejor'}
                    style={{cursor: 'inherit', minHeight: '340px', resize: 'vertical'}}
                    required={true}
                  />
                  <input
                    style={{display: 'inline', maxWidth: '140px', textAlign: 'center'}}
                    type='submit'
                    className='contactFormInput'
                    value='Enviar'
                  />
                  </form>
                </div>
              }
            </div>
          </div>
      </div>
    )
  }
}

export default Contact