import React from 'react'
import './about.css'

class About extends React.Component {
  componentDidMount () {
    window.scrollTo(0, 0)
  }
  render() {
    return (
      <div>About</div>
    )
  }
}

export default About